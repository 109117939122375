'use client';

import { useEffect } from 'react';
import { captureException } from '@sentry/nextjs';
import ErrorBoundaryMessage from '@app/components/error-boundary-message/error-boundary-message';

export default function Error({
    error,
    reset,
}: {
    error: Error & { digest?: string };
    reset: () => void;
}) {
    useEffect(() => {
        captureException(error);
    }, [error]);

    return <ErrorBoundaryMessage reset={reset} />;
}
