import React from 'react';
import Link from 'next/link';
import styles from './error-boundary-message.module.css';
import { Button } from 'mmds';

const ErrorBoundaryMessage = ({ reset }) => {
    return (
        <div className={styles.root}>
            <div className={styles.message}>
                <h1>SORRY</h1>
                <p>Something unexpected happened.</p>
                <Button variant="text" label="Try again" onClick={reset} />
            </div>
            <Link
                href="https://support.maap.cc/hc/en-us"
                className={styles.link}
            >
                Customer Support
            </Link>
            <div className={styles.features}>
                <div className={styles.feature}>
                    <Link href={`/collections/maap-new-arrivals`}>
                        SHOP MAN
                    </Link>
                </div>
                <div className={styles.feature}>
                    <Link href={`/collections/new-arrivals-woman`}>
                        SHOP WOMAN
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default ErrorBoundaryMessage;
